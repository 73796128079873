import React from "react"
import BlogHighlight from "../components/BlogHighlight"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Blogi"
      description="Vivechon blogi teknologia aiheista, joko läheltä tai kaukaa. Tutustu artikkeleihin ja löydä vinkkejä!"
      pageImage={metaImg}
      pageUrl={links.blog}
    />

    <section id="hero">
      <Hero hideContact>
        <HeroTitle>
          <h1 className="block">Blogi</h1>
          <span className="block text-indigo-400">kirjoituksia</span>
        </HeroTitle>
        <HeroLead>Kirjoituksia, mielipiteitä ja jotain aivan muuta</HeroLead>
      </Hero>
    </section>
    <BlogHighlight />
  </Layout>
)
