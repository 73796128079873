import { Link } from "gatsby"
import React from "react"
import blogListing from "../content/blog/blog-listing"
import Container from "./Container"

const posts = [
  blogListing.mobileAppRelease,
  blogListing.whatApplicationCosts,
  blogListing.mobileAppBuyerGuide,
  blogListing.fromIdeaToApp,
  blogListing.websiteBuyerGuide,
]

export default (): JSX.Element => (
  <Container>
    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      {posts.map(post => (
        <div
          key={post.title}
          className="flex flex-col rounded-lg shadow-lg overflow-hidden"
        >
          <div className="flex-shrink-0">{post.ImageElement}</div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600">
                {post.category}
              </p>
              <Link to={post.href} className="block mt-2">
                <h2 className="text-xl font-semibold text-gray-900">
                  {post.title}
                </h2>
                <p className="mt-3 text-base text-gray-500">
                  {post.description}
                </p>
              </Link>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <span className="sr-only">{post.author.name}</span>
                {post.author.ImageElement}
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {post.author.name}
                </p>
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={post.dateTime}>{post.date}</time>
                  <span aria-hidden="true">&middot;</span>
                  <span>{post.readingTime} read</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Container>
)
